import React from "react";
import images from "../../../assets/images";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HomeAbout = () => {
  const { t } = useTranslation();
  return (
    <div className="my-10 flex-center w-full">
      <div className="container">
        <div className="w-full flex-start gap-10 md:flex-row flex-col">
          <img
            src={images.about}
            className="w-96 h-auto rounded-md"
            alt="about"
          />
          <div className="flex-start flex-col gap-1">
            <h1 className="text-8xl">{t("nujoom")}</h1>
            <h2 className="text-2xl">{t("kawkab")}</h2>
            <p className="text-sm text-grey">{t("about1")}</p>
            <Link to="/about" title="about" className="primary-button mt-10">
              {t("about")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeAbout;
