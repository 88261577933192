import React from 'react'

const Loading = () => {
    return (
        <div className='flex justify-center gap-3 items-center bg-white fixed top-0 left-0 w-full h-full z-50'>
            <span className='sr-only'>Loading...</span>
            <div className='h-8 w-8 bg-primary rounded-full animate-bounce [animation-delay:-0.3s]'></div>
            <div className='h-8 w-8 bg-primary rounded-full animate-bounce [animation-delay:-0.15s]'></div>
            <div className='h-8 w-8 bg-primary rounded-full animate-bounce'></div>
        </div>
    )
}

export default Loading