import React from "react";
import useFetch from "../../hooks/useFetch";
import { Loading } from "../../components";
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft } from "react-icons/fa";

const ProductDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, loading } = useFetch(`https://api.nujoomalkawakb.com/api/products/${id}?lang=${localStorage.getItem("currectLanguage") === "kr"
    ? "ku"
    : localStorage.getItem("currectLanguage")
    }`);
  if (loading && !data) {
    return <Loading />
  } else {
    return (
      <>
        <section className="section my-10">
          <div className="container flex-center gap-5 md:flex-row flex-col">
            <div className="w-[44%] flex-center flex-col gap-5">
              <div className="flex-center max-w-[300px] w-full h-[300px] bg-white rounded-full overflow-hidden">
                <img src={data.partner_image_img} alt={data.title} className="w-[90%] " />
              </div>
              <h3 className="w-[300px] py-4 bg-grey rounded-xl text-white text-2xl flex-center">
                {data.partner_title}
              </h3>
            </div>
            <div className="md:w-[44%] w-full flex-center">
              <div className="max-w-[400px] w-full flex-center flex-col rounded-md overflow-hidden">
                <img
                  src={data.image_url}
                  alt={data.title}
                  className="w-full sm:h-[300px] h-[210px]"
                />
                <div className="w-full py-3 bg-primary text-white sm:text-2xl text-base px-1 text-center flex-center flex-col">
                  <div className="flex-start gap-3 w-full">
                    <h3>{t("name")}</h3>
                    <span>{data.partner_title}</span>
                  </div>
                  <div className="flex-start gap-3 w-full">
                    <h3>{("color")}</h3>
                    <span>{data.color}</span>
                  </div>
                  <div className="flex-start gap-3 w-full">
                    <h3>{t("code")}</h3>
                    <span>{data.code}</span>
                  </div>
                </div>
              </div>
            </div>
            <button onClick={() => navigate(-1)} className="fixed bottom-20 sm:right-20 right-10 bg-white sm:w-16 w-12 sm:h-16 h-12 rounded-full flex-center">
              <FaChevronLeft className="text-2xl" />
            </button>
          </div>
        </section>
      </>
    );
  }
};

export default ProductDetails;
