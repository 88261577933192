import React, { useContext, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
// import useDelete from "../../../hooks/useDelete";
import { Link } from "react-router-dom";
import { CreatedWarning, Loading } from "../../../components";
import { useTranslation } from "react-i18next";
import useDelete from "../../../hooks/useDelete";
import { MdReportGmailerrorred } from "react-icons/md";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AddButton } from "../../AdminComponents";

const ViewProducts = () => {
  const { t } = useTranslation();
  const [isSent, setIsSent] = useState(false);

  const { deleteRequest, deleteLoading, deleteError } = useDelete();
  const { data, loading, error, refetch } = useFetch(
    `https://api.nujoomalkawakb.com/api/products/all/admin?lang=${localStorage.getItem("currectLanguage") === "kr"
      ? "ku"
      : localStorage.getItem("currectLanguage")
    }`
  );
  const [getData, setGetData] = useState([]);

  const handleDelete = async (id) => {
    setShowModal(!showModal);
    try {
      await deleteRequest(`https://api.nujoomalkawakb.com/api/products/delete/${id}`);
      setIsSent(true);
      refetch();
    } catch (err) {
      setIsSent(false);
    }
  };

  useEffect(() => {
    if (!loading && data) {
      setGetData(data);
      localStorage.setItem("totalProducts", data.length);
    }
  }, [loading, data]);

  const dateFormat = (date) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  // remove and show modal
  const [showModal, setShowModal] = useState(false);
  const [modalId, setModalId] = useState(false);

  const handleModal = (id) => {
    setShowModal(!showModal);
    setModalId(id);
  };
  if (loading || !data) {
    return <Loading />;
  } else {
    return (
      <>
        <div className="admin-section">
          <div className="flex-center flex-col w-full xl:flex-[0.8] flex-1 gap-8">
            <AddButton title={t("addProducts")} link="/admin/products/new" />
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-full">
              <table className="w-full  text-sm text-left text-slate-300">
                <thead className="text-sm font-light uppercase bg-primary text-white">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 font-light change-font"
                    >
                      ID
                    </th>
                    <th scope="col" className="px-6 py-3 font-light text-start">
                      {t("title")}
                    </th>
                    <th scope="col" className="px-6 py-3 font-light text-start">
                      -
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 font-light text-start min-w-[20ch]"
                    >
                      -
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 font-light text-start min-w-[20ch]"
                    >
                      {t("createAt")}
                    </th>
                    <th scope="col" className="px-6 py-3 font-light text-start">
                      {t("actions")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {getData.map((product) => (
                    <tr
                      key={product.id}
                      className="bg-backgroundTerinary border-b border-backgroundSecondary  hover:bg-lightGrey"
                    >
                      <td className="w-4 p-4 text-start">{product.id}</td>
                      <th
                        scope="row"
                        className="px-6 py-4 text-start max-w-[24ch] text-ellipsis overflow-hidden whitespace-nowrap font-light"
                      >
                        <Link
                          className="link-hover"
                          to={`/products/${product.id}`}
                          rel="noreferrer noopener"
                          target="_blank"
                        >
                          {product.title}
                        </Link>
                      </th>
                      <td className="px-6 py-4 text-start max-w-[30ch] text-ellipsis overflow-hidden whitespace-nowrap">
                        -
                      </td>
                      <td className="px-6 py-4 text-start change-font max-w-[30ch] text-ellipsis overflow-hidden whitespace-nowrap">
                        -
                      </td>
                      <td className="px-6 py-4 change-font text-start">
                        {dateFormat(product.created_at)}
                      </td>
                      <td className="px-6 py-4 flex justify-start items-center gap-2 text-start">
                        <button
                          className="font-medium text-primary hover:underline"
                          onClick={() => handleModal(product.id)}
                        >
                          {t("delete")}
                        </button>
                        <Link
                          to={`/admin/products/${product.id}`}
                          className="font-medium  hover:underline"
                          title={product.title}
                        >
                          {t("edit")}
                        </Link>
                      </td>
                      {/* delete modal */}
                      {product.id === modalId && showModal === true && (
                        <>
                          <div className="max-w-[500px] w-[90%] h-auto flex justify-center items-center flex-col gap-5 p-5 rounded-xl bg-backgroundSecondary fixed top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] z-20">
                            <h1 className="sm:text-2xl text-lg">
                              {t("deleteQuestion")}
                            </h1>
                            <span className="text-slate-300">
                              {t("deleteText")}
                            </span>
                            <div className="flex-center gap-5">
                              <button
                                className="primary-button !text-black !bg-lightGrey"
                                onClick={() => handleModal(product.id)}
                              >
                                {"cancel"}
                              </button>
                              <button
                                className="primary-button !bg-red"
                                onClick={() => handleDelete(product.id)}
                              >
                                {"confirm"}
                              </button>
                            </div>
                          </div>
                          <button
                            className="bg-black bg-opacity-50 z-10 fixed top-0 left-0 w-full h-full"
                            onClick={() => handleModal(product.id)}
                          ></button>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {!isSent && deleteError && deleteError.response && (
            <CreatedWarning
              icon={<MdReportGmailerrorred className="text-9xl text-primary" />}
              message={t("deleteError")}
              moreInfo={t("deleteErrorText")}
              key={1}
            />
          )}
          {isSent && (
            <CreatedWarning
              icon={<AiOutlineCheckCircle className="text-9xl text-primary" />}
              message={t("isDeleted")}
              key={2}
            />
          )}
        </div>
      </>
    );
  }
};

export default ViewProducts;
