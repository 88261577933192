import React, { useEffect, useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdReportGmailerrorred } from "react-icons/md";
import SendData from "../../../hooks/useSendData";
import { useTranslation } from "react-i18next";
import { ButtonLoading, CreatedWarning, Loading } from "../../../components";
import useFetch from "../../../hooks/useFetch";
import { useParams } from "react-router-dom";
import useUpdateData from "../../../hooks/useUpdate";
const EditBranches = () => {
  const { id } = useParams();
  const { data, loading, error } = useFetch(
    `https://api.nujoomalkawakb.com/api/branches/${id}`
  );
  const { t } = useTranslation();
  const { updateData, updateError, updateLoading, put } = useUpdateData();

  const [isSent, setIsSent] = useState(false);
  const defaultFormData = {
    title_en: "",
    title_ar: "",
    title_ku: "",
    src: "",
    phone: "",
    email: "",
    location_en: "",
    location_ar: "",
    location_ku: "",
  };
  const [formData, setFormData] = useState(defaultFormData);

  useEffect(() => {
    if (!loading && data) {
      // Update formData with data when available
      setFormData({
        title_en: data.title_en || "",
        title_ar: data.title_ar || "",
        title_ku: data.title_ku || "",
        email: data.email || "",
        location_en: data.location_en || "",
        location_ar: data.location_ar || "",
        location_ku: data.location_ku || "",
        phone: data.phone || "",
        src: data.src || "",
      });
    }
  }, [data, loading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();

    formDataToSend.append("title_en", formData.title_en);
    formDataToSend.append("title_ar", formData.title_ar);
    formDataToSend.append("title_ku", formData.title_ku);
    formDataToSend.append("src", formData.src);
    formDataToSend.append("phone", formData.phone);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("location_en", formData.location_en);
    formDataToSend.append("location_ar", formData.location_ar);
    formDataToSend.append("location_ku", formData.location_ku);
    try {
      await put(
        `https://api.nujoomalkawakb.com/api/branches/update/${id}`,
        formDataToSend
      );
      setIsSent(true);
    } catch (err) {
      setIsSent(false);
    }
  };
  if (loading || !data) {
    return <Loading />;
  } else {
    return (
      <>
        <div className="flex-center flex-col w-full xl:flex-[0.8] mb-10 flex-1 mt-28 gap-8">
          <div className="flex-center gap-5 md:flex-row flex-col">
            <div className="flex-start gap-2 flex-col">
              <label htmlFor="title_en">{t("title_en")}</label>
              <input
                type="text"
                id="title_en"
                value={formData.title_en}
                onChange={handleChange}
                className="outline-blue border-grey border rounded h-10 sm:w-[230px] w-[330px] bg-white bg-opacity-0 px-2"
                name="title_en"
              />
            </div>
            <div className="flex-start gap-2 flex-col">
              <label htmlFor="title_ar">{t("title_ar")}</label>
              <input
                type="text"
                value={formData.title_ar}
                onChange={handleChange}
                id="title_ar"
                name="title_ar"
                className="outline-blue border-grey border rounded h-10 sm:w-[230px] w-[330px] bg-white bg-opacity-0 px-2"
              />
            </div>
            <div className="flex-start gap-2 flex-col">
              <label htmlFor="title_ku">{t("title_ku")}</label>
              <input
                type="text"
                value={formData.title_ku}
                onChange={handleChange}
                id="title_ku"
                name="title_ku"
                className="outline-blue border-grey border rounded h-10 sm:w-[230px] w-[330px] bg-white bg-opacity-0 px-2"
              />
            </div>
          </div>
          <div className="flex-center gap-5 md:flex-row flex-col">
            <div className="flex-start gap-2 flex-col">
              <label htmlFor="email">{t("email")}</label>
              <input
                type="text"
                value={formData.email}
                onChange={handleChange}
                id="email"
                name="email"
                className="outline-blue border-grey border rounded h-10 sm:w-[370px] w-[330px] bg-white bg-opacity-0 px-2"
              />
            </div>
            <div className="flex-start gap-2 flex-col">
              <label htmlFor="phone">{t("phoneNumber")}</label>
              <input
                type="number"
                value={formData.phone}
                onChange={handleChange}
                id="phone"
                name="phone"
                className="outline-blue border-grey border rounded h-10 sm:w-[370px] w-[330px] bg-white bg-opacity-0 px-2"
              />
            </div>
          </div>
          <div className="flex-center gap-5 md:flex-row flex-col">
            <div className="flex-start gap-2 flex-col">
              <label htmlFor="location_en">{t("locationEn")}</label>
              <input
                type="text"
                id="location_en"
                value={formData.location_en}
                onChange={handleChange}
                className="outline-blue border-grey border rounded h-10 sm:w-[230px] w-[330px] bg-white bg-opacity-0 px-2"
                name="location_en"
              />
            </div>
            <div className="flex-start gap-2 flex-col">
              <label htmlFor="location_ar">{t("locationAr")}</label>
              <input
                type="text"
                id="location_ar"
                value={formData.location_ar}
                onChange={handleChange}
                className="outline-blue border-grey border rounded h-10 sm:w-[230px] w-[330px] bg-white bg-opacity-0 px-2"
                name="location_ar"
              />
            </div>
            <div className="flex-start gap-2 flex-col">
              <label htmlFor="location_ku">{t("locationKu")}</label>
              <input
                type="text"
                id="location_ku"
                value={formData.location_ku}
                onChange={handleChange}
                className="outline-blue border-grey border rounded h-10 sm:w-[230px] w-[330px] bg-white bg-opacity-0 px-2"
                name="location_ku"
              />
            </div>
          </div>
          <div className="flex-start gap-2 flex-col">
            <label htmlFor="textarea">{t("map")}</label>
            <span className="text-sm text-grey">{t("onlySrc")}</span>
            <textarea
              name="src"
              id="textarea"
              value={formData.src}
              onChange={handleChange}
              className="outline-blue border-grey border rounded h-32 md:w-[720px] sm:w-[450px] w-[330px] bg-white bg-opacity-0 px-2"
            />
          </div>
          <button
            onClick={handleSubmit}
            type="submit"
            disabled={updateLoading}
            className={`sm:w-[450px] w-[90%] relative h-[45px] bg-primary text-xl py-2 hover:bg-secondary text-white duration-300 rounded-md ${updateLoading ? "disabled" : ""
              }`}
          >
            {!updateLoading && <span>{t("send")}</span>}
            {updateLoading && <ButtonLoading />}
          </button>
        </div>
        {isSent && (
          <CreatedWarning
            icon={<AiOutlineCheckCircle className="text-9xl text-primary" />}
            message={t("updatedSuccessfully")}
          />
        )}
        {updateError && (
          <CreatedWarning
            icon={<MdReportGmailerrorred className="text-9xl text-primary" />}
            message={t("fillFields")}
          />
        )}
      </>
    );
  }
};

export default EditBranches;
