import React from "react";
import images from "../../../assets/images";
import { useTranslation } from "react-i18next";

const HomeService = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full flex-center md:flex-row flex-col">
      <div className="bg-black flex-center flex-col gap-5 p-5 w-full">
        <div className="w-full flex justify-start">
          <h3 className="text-2xl text-white w-[10ch]">{t("chooseTower")}</h3>
        </div>
        <div className="w-full flex justify-end">
          <img src={images.tower} alt="tower" className="w-[200px] h-auto" />
        </div>
      </div>
      <div className="bg-white flex-center flex-col gap-5 p-5 w-full">
        <div className="w-full flex justify-start">
          <h3 className="text-2xl text-black w-[14ch]">{t("iraq")}</h3>
        </div>
        <div className="w-full flex justify-end">
          <img src={images.iraq} alt="map" className="w-[200px] h-auto" />
        </div>
      </div>
      <div className="bg-primary flex-center flex-col gap-5 p-5 w-full">
        <div className="w-full flex justify-start">
          <h3 className="text-2xl text-white w-[9ch]">{t("inspire")}</h3>
        </div>
        <div className="w-full flex justify-end">
          <img src={images.light} alt="light" className="w-[200px] h-auto" />
        </div>
      </div>
    </div>
  );
};

export default HomeService;
