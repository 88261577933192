import logo from "./logo.png";
import kitchen from "./kitchen.jpg";
import iraq from "./iraq.svg";
import tower from "./tower.svg";
import light from "./light.svg";
import about from "./about.jpg";
import category from "./category-2.png";
import branch from "./branch.png";
import noImg from "./no-image.jpg";
const images = {
  logo,
  noImg,
  kitchen,
  about,
  light,
  tower,
  iraq,
  category,
  branch,
};
export default images;
