import React from "react";
import images from "../../assets/images";
import useFetch from "../../hooks/useFetch";
import { useParams } from "react-router-dom";
import { Loading } from "../../components";
import { useTranslation } from "react-i18next";

const PartnersDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data, loading } = useFetch(
    `https://api.nujoomalkawakb.com/api/partners/${id}?lang=${localStorage.getItem("currectLanguage") === "kr"
      ? "ku"
      : localStorage.getItem("currectLanguage")
    }`
  );
  if (loading && !data) {
    return <Loading />
  } else {
    return (
      <>
        <section className="section mt-10">
          <div className="container flex-center flex-col gap-5">
            <h1 className="sm:text-4xl text-2xl border-b border-b-grey w-full pb-3 text-center">
              {t("ourPartners")}
            </h1>
            <div className="flex-center flex-col  gap-4 w-full mt-10">
              <div className="flex-center gap-5 w-full sm:flex-row flex-col">
                <div className="bg-white sm:w-[200px] sm:h-[200px] w-full h-[300px] rounded-lg flex-center flex-shrink-0">
                  <img
                    src={data.image_url}
                    alt={data.title}
                    className="max-w-full sm:w-[170px] w-[90%] rounded-md h-auto"
                  />
                </div>
                <h3 className="sm:text-4xl text-2xl text-grey">{data.title}</h3>
              </div>

              <span className="text-grey sm:w-[70%] w-full text-center">
                {data.description}
              </span>
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default PartnersDetails;
