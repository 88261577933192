import React, { useEffect, useState } from "react";
import CardDashboard from "./CardDashboard";
import { FaCodeBranch, FaHandshake, FaRegNewspaper, FaShoppingCart } from "react-icons/fa";
import { IoMdContacts } from "react-icons/io";

import { MdCategory } from "react-icons/md";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t } = useTranslation();
  const [prroductsCount, setProductsCount] = useState(null);
  const [categoriesCount, setCategoriesCount] = useState(null);
  const [branchCount, setBranchCount] = useState(null);
  const [partnerCount, setPartnerCount] = useState(null);
  const [newsCount, setNewsCount] = useState(null);
  const [contactCount, setContactCount] = useState(null);
  useEffect(() => {
    setProductsCount(localStorage.getItem("totalProducts") || 0);
    setCategoriesCount(localStorage.getItem("totalCategories") || 0);
    setBranchCount(localStorage.getItem("totalBranches") || 0);
    setPartnerCount(localStorage.getItem("totalPartners") || 0);
    setNewsCount(localStorage.getItem("totalNews") || 0);
    setContactCount(localStorage.getItem("totalContact") || 0);
  }, []);
  return (
    <>
      <div className="admin-section">
        <div className="flex-center w-full">
          <div className="grid lg:grid-cols-3 lg:grid-rows-2 md:grid-rows-3 md:grid-cols-2 md:grid-rows grid-cols-1 gap-5">
            <CardDashboard
              title={t("totalProducts")}
              icon={<FaShoppingCart />}
              number={prroductsCount}
            />
            <CardDashboard
              title={t("totalCategories")}
              icon={<MdCategory />}
              number={categoriesCount}
            />
            <CardDashboard
              title={t("totalBranches")}
              icon={<FaCodeBranch />}
              number={branchCount}
            />
            <CardDashboard
              title={t("totalNews")}
              icon={<FaRegNewspaper />}
              number={newsCount}
            />
            <CardDashboard
              title={t("totalPartners")}
              icon={<FaHandshake />}
              number={partnerCount}
            />
            <CardDashboard
              title={t("totalContacts")}
              icon={<IoMdContacts />}
              number={contactCount}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
