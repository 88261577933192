import React, { useEffect, useState } from "react";
import images from "./../../assets/images/index";
import { FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import { FaInstagram } from "react-icons/fa";
import { GrLanguage } from "react-icons/gr";
import { CiMenuFries } from "react-icons/ci";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const handleSearch = () => {
    navigate(`/search?query=${searchText}`);
  };
  const handleKeySearch = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };
  const { t } = useTranslation();
  // lang
  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "en",
    },
    {
      code: "kr",
      name: "کوردی",
      country_code: "kr",
      dir: "rtl",
    },
    {
      code: "ar",
      name: "العربیة",
      country_code: "ir",
      dir: "rtl",
    },
  ];
  const currentLanguageCode = Cookies.get("i18next") || "ar";
  const currentLanguage = languages.find(
    (el) => el.code === currentLanguageCode
  );
  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    localStorage.setItem("currectLanguage", currentLanguage.code);
  }, [currentLanguage]);
  //   lang end

  const [langIsOpen, setLangIsOpen] = useState(false);
  const [navIsOpen, setNavIsOpen] = useState(false);
  const openLang = () => {
    setLangIsOpen(!langIsOpen);
  };
  const closeLang = () => {
    setLangIsOpen(false);
  };
  const handleNav = () => {
    setNavIsOpen(!navIsOpen);
  };
  const activeLink = "xl:text-xl md:text-base text-xl link-hover text-primary";
  const normalLink = "xl:text-xl md:text-base text-xl link-hover";
  return (
    <>
      <nav className="w-full flex justify-start items-center h-[100px]">
        <Link
          to="/"
          title="home"
          className="flex-[0.2] sm:flex justify-center items-center hidden flex-shrink-0 bg-backgroundTertiary h-full"
        >
          <img
            src={images.logo}
            className="lg:w-auto w-[100px] h-[100px] flex-shrink-0"
            alt="logo"
          />
        </Link>
        <div className="sm:flex-[0.8] flex-1 flex-start flex-col h-full sm:w-auto w-full">
          <div className="flex justify-between gap-4 items-start bg-black w-full p-3 overflow-x-auto flex-shrink-0">
            <div className="flex-center h-full gap-2 overflow-x-auto flex-shrink-0">
              <div className="flex-center gap-1 text-white">
                <FaPhone />
                <a
                  className="text-sm  change-font"
                  href="https://api.whatsapp.com/send?phone=9647504482440"
                  title="number"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  +964 750 448 2440
                </a>
              </div>
              <span className="h-full w-[1px] bg-grey"></span>
              <div className="flex-center gap-1 text-white">
                <FaPhone />
                <a
                  className="text-sm  change-font"
                  href="https://api.whatsapp.com/send?phone=9647501128070"
                  title="number"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  +964 750 112 8070
                </a>
              </div>
              <span className="h-full w-[1px] bg-grey"></span>
              <div className="flex-center gap-1 text-white">
                <MdEmail />
                <a
                  href="mailto:nujoomalkawakb@gmail.com"
                  className="text-sm  change-font"
                >
                  nujoomalkawakb@gmail.com
                </a>
              </div>
              <span className="h-full w-[1px] bg-grey"></span>
              <div className="flex-center gap-1 text-white">
                <FaLocationDot />
                <span className="text-sm">{t("companyLocation")}</span>
              </div>
            </div>
            <div className="flex-center gap-3 h-full flex-shrink-0">
              <a
                href="https://api.whatsapp.com/send?phone=9647501128070"
                className="text-white text-xl"
                title="number"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaWhatsapp />
              </a>
              <span className="h-full w-[1px] bg-grey"></span>
              <a
                href="mailto:nujoomalkawakb@gmail.com"
                className="text-white text-xl"
              >
                <MdEmail />
              </a>
              <span className="h-full w-[1px] bg-grey"></span>
              <a
                href="https://www.facebook.com/nujoom.alkawakb"
                title="number"
                className="text-white text-xl"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF />
              </a>
              <span className="h-full w-[1px] bg-grey"></span>
              <a
                href="https://www.instagram.com/nujoom_alkawakb/"
                title="number"
                className="text-white text-xl"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
            </div>
          </div>
          <div className="flex justify-between items-center h-full px-3 gap-2 w-full bg-white">
            {navIsOpen && (
              <button
                onClick={handleNav}
                className="md:hidden block absolute top-0 left-0 w-full h-full z-20 bg-black bg-opacity-50"
              ></button>
            )}
            <div
              className={`flex md:p-0 p-10 md:items-center  items-end justify-start lg:gap-7 gap-2 h-full md:relative fixed bg-white md:bg-[#ffffff00] md:text-black text-black top-0 md:flex-row md:w-[100%] sm:w-[50%] md:right-auto w-[80%] flex-col z-30 duration-300 ${navIsOpen ? "right-0" : "-right-[600px]"
                }`}
            >
              <img
                src={images.logo}
                className="lg:w-auto w-[100px] h-[100px] sm:hidden block"
                alt="logo"
              />
              <NavLink
                to="/"
                title="home"
                onClick={handleNav}
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
              >
                {t("home")}
              </NavLink>
              <span className="h-full w-[1px] bg-lightGrey xl:block hidden"></span>
              <NavLink
                to="/partners"
                onClick={handleNav}
                title="partners"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
              >
                {t("partners")}
              </NavLink>
              <span className="h-full w-[1px] bg-lightGrey xl:block hidden"></span>
              <NavLink
                to="/products"
                onClick={handleNav}
                title="products"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
              >
                {t("products")}
              </NavLink>
              <span className="h-full w-[1px] bg-lightGrey xl:block hidden"></span>
              <NavLink
                to="/branches"
                onClick={handleNav}
                title="branches"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
              >
                {t("branches")}
              </NavLink>
              <span className="h-full w-[1px] bg-lightGrey xl:block hidden"></span>
              <NavLink
                to="/news"
                onClick={handleNav}
                title="news"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
              >
                {t("news")}
              </NavLink>
              <span className="h-full w-[1px] bg-lightGrey xl:block hidden"></span>
              <NavLink
                to="/about"
                onClick={handleNav}
                title="about"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
              >
                {t("about")}
              </NavLink>
              <span className="h-full w-[1px] bg-lightGrey xl:block hidden"></span>
              <NavLink
                to="/contact"
                onClick={handleNav}
                title="contact"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
              >
                {t("contact")}
              </NavLink>
            </div>
            <div className="flex-center gap-5 sm:w-auto w-full">
              <div className="relative w-full">
                <input
                  className="h-[40px] lg:w-[250px] sm:w-[150px] w-full bg-backgroundTertiary rounded-lg px-3 outline-none"
                  placeholder={t("search")}
                  type="search"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyDown={handleKeySearch}
                />
                <button
                  onClick={handleSearch}
                  className="z-10 absolute top-[50%] -translate-y-[50%] text-xl text-grey right-2 rtl:left-2 rtl:right-auto"
                >
                  <CiSearch />
                </button>
              </div>
              <div className="relative">
                <button
                  onClick={openLang}
                  className="w-[40px] text-xl text-grey h-[40px] bg-backgroundTertiary rounded-full flex-center"
                >
                  <GrLanguage />
                </button>
                {langIsOpen && (
                  <div className="flex-start flex-col gap-2 bg-backgroundSecondary absolute top-14 px-5 py-2 rounded-md z-[600] right-0 rtl:left-0 rtl:right-auto">
                    {languages.map(({ code, name, country_code }) => (
                      <button
                        key={code}
                        onClick={() => {
                          i18next.changeLanguage(code);
                          closeLang();
                          window.location.reload();
                        }}
                        disabled={code === currentLanguageCode}
                        className={
                          code === currentLanguageCode ? "text-primary" : ""
                        }
                      >
                        {name}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <button
              className="md:hidden block text-2xl text-grey"
              onClick={handleNav}
            >
              <CiMenuFries />
            </button>
          </div>
        </div>
        {langIsOpen && (
          <button
            className="fixed top-0 left-0 w-full h-full z-50"
            onClick={closeLang}
          ></button>
        )}
      </nav>
    </>
  );
};

export default Navbar;
