import React, { useEffect, useState } from "react";
import images from "../../assets/images";
import { Link } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { Loading } from "../../components";
import { useTranslation } from "react-i18next";

const Partners = () => {
  const { t } = useTranslation();
  const { data, loading } = useFetch(
    `https://api.nujoomalkawakb.com/api/partners/all?lang=${
      localStorage.getItem("currectLanguage") === "kr"
        ? "ku"
        : localStorage.getItem("currectLanguage")
    }`
  );
  const [partnersData, setPartnersData] = useState([]);
  useEffect(() => {
    if (!loading && data.length > 0) {
      setPartnersData(data);
    }
  }, [loading, data]);
  if (loading && !data) {
    return <Loading />;
  } else {
    return (
      <>
        <section className="section mt-10">
          <div className="container flex-center flex-col gap-5">
            <h1 className="sm:text-4xl text-2xl border-b border-b-grey w-full pb-3 text-center">
              {t("ourPartners")}
            </h1>
            {partnersData.map((partner, i) => (
              <div
                key={i}
                className="flex-start sm:flex-row flex-col gap-4 w-full pb-3 border-b border-b-grey mt-10"
              >
                <div className="bg-white sm:w-[270px] sm:h-[270px] w-full h-[300px] rounded-lg flex-center flex-shrink-0">
                  <img
                    src={partner.image_url}
                    alt={partner.title}
                    className="max-w-full sm:w-[200px] w-[90%] rounded-md h-auto"
                  />
                </div>
                <div className="flex-start w-full flex-col gap-5">
                  <h3 className="sm:text-4xl text-2xl text-grey">
                    {partner.title}
                  </h3>
                  <span className="text-grey sm:block hidden">
                    {partner.description.substring(0, 250)}...
                  </span>
                  <Link
                    to={`/partners/${partner.id}`}
                    title={partner.title}
                    className="primary-button flex-shrink-0"
                  >
                    {t("learnMore")}
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </section>
      </>
    );
  }
};

export default Partners;
