import React from 'react'
import { useTranslation } from 'react-i18next';
import useFetch from '../../../hooks/useFetch';
import { Loading } from '../../../components';
import { useParams } from 'react-router-dom';

const ViewOneContact = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const { data, loading, error, refetch, } = useFetch(
        `https://api.nujoomalkawakb.com/api/contact/${id}`
    );
    if (loading || !data) {
        return <Loading />;
    } else {
        return (
            <>
                <div className="admin-section">
                    <div className="flex-center flex-col w-full xl:flex-[0.8] flex-1 gap-8">
                        <div className='flex-start flex-col w-[90%] gap-5'>
                            <div className="flex-start gal-2 flex-col w-full">
                                <h3>{t("fullName")}:</h3>
                                <span className='text-grey'>{data.full_name}</span>
                            </div>
                            <div className="flex-start gal-2 flex-col w-full">
                                <h3>{t("email")}:</h3>
                                <span className='text-grey'>{data.email}</span>
                            </div>
                            <div className="flex-start gal-2 flex-col w-full">
                                <h3>{t("phoneNumber")}:</h3>
                                <span className='text-grey'>{data.phone_number}</span>
                            </div>
                            <div className="flex-start gal-2 flex-col w-full">
                                <h3>{t("description")}:</h3>
                                <span className='text-grey'>{data.description}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ViewOneContact