import React from "react";
import { useTranslation } from "react-i18next";
import { FaShoppingCart } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { GiShakingHands } from "react-icons/gi";
const HomeStatics = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="w-full my-14 flex-center">
        <div className="container flex justify-between items-center gap-2">
          <div className="md:w-[300px] sm:w-[170px] w-[100px] md:h-[300px] sm:h-[170px] h-[100px] bg-white sm:p-5 p-2 rounded-lg flex-center flex-col gap-5">
            <div className="bg-purple text-2xl bg-opacity-25 rounded-lg w-14 h-14 flex-center">
              <FaShoppingCart />
            </div>
            <h3 className="md:text-2xl sm:text-base text-xs text-purple">
              {t("numOfProduct")}
            </h3>
          </div>
          <div className="md:w-[300px] sm:w-[170px] w-[100px] md:h-[300px] sm:h-[170px] h-[100px] bg-white sm:p-5 p-2 rounded-lg flex-center flex-col gap-5">
            <div className="bg-blue bg-opacity-25 text-2xl rounded-lg w-14 h-14 flex-center">
              <FaUsers />
            </div>
            <h3 className="md:text-2xl sm:text-base text-xs text-blue">
              {t("numOfEmployee")}
            </h3>
          </div>
          <div className="md:w-[300px] sm:w-[170px] w-[100px] md:h-[300px] sm:h-[170px] h-[100px] bg-white sm:p-5 p-2 rounded-lg flex-center flex-col gap-5">
            <div className="bg-orange bg-opacity-25 text-2xl rounded-lg w-14 h-14 flex-center">
              <GiShakingHands />
            </div>
            <h3 className="md:text-2xl sm:text-base text-xs text-orange">
              {t("numOfPartners")}
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeStatics;
