import React, { useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdReportGmailerrorred } from "react-icons/md";
import SendData from "../../../hooks/useSendData";
import { useTranslation } from "react-i18next";
import { ButtonLoading, CreatedWarning } from "../../../components";
const AddAdmins = () => {
  const { t } = useTranslation();
  const { createData, createError, createLoading, post } = SendData();

  const [isSent, setIsSent] = useState(false);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();

    formDataToSend.append("username", formData.username);
    formDataToSend.append("password", formData.password);
    try {
      await post("https://api.nujoomalkawakb.com/api/admins/create", formDataToSend);
      setIsSent(true);
    } catch (err) {
      setIsSent(false);
    }
  };

  return (
    <>
      <div className="flex-center flex-col w-full xl:flex-[0.8] mb-10 flex-1 mt-28 gap-8">
        <div className="flex-center gap-5 md:flex-row flex-col">
          <div className="flex-start gap-2 flex-col">
            <label htmlFor="username">{t("username")}</label>
            <input
              type="text"
              value={formData.username}
              onChange={handleChange}
              id="username"
              name="username"
              className="outline-blue border-grey border rounded h-10 sm:w-[370px] w-[330px] bg-white bg-opacity-0 px-2"
            />
          </div>
          <div className="flex-start gap-2 flex-col">
            <label htmlFor="password">{t("password")}</label>
            <input
              type="text"
              value={formData.password}
              onChange={handleChange}
              id="password"
              name="password"
              className="outline-blue border-grey border rounded h-10 sm:w-[370px] w-[330px] bg-white bg-opacity-0 px-2"
            />
          </div>
        </div>
        <button
          onClick={handleSubmit}
          type="submit"
          disabled={createLoading}
          className={`sm:w-[450px] w-[90%] relative h-[45px] bg-primary text-xl py-2 hover:bg-secondary text-white duration-300 rounded-md ${createLoading ? "disabled" : ""
            }`}
        >
          {!createLoading && <span>{t("send")}</span>}
          {createLoading && <ButtonLoading />}
        </button>
      </div>
      {isSent && (
        <CreatedWarning
          icon={<AiOutlineCheckCircle className="text-9xl text-primary" />}
          message={t("createdSuccessfully")}
        />
      )}
      {createError && (
        <CreatedWarning
          icon={<MdReportGmailerrorred className="text-9xl text-primary" />}
          message={t("fillFields")}
        />
      )}
    </>
  );
};

export default AddAdmins;
