import React from "react";
import HomeSlider from "./HomeComponents/HomeSlider";
import HomeService from "./HomeComponents/HomeService";
import HomeAbout from "./HomeComponents/HomeAbout";
import HomeProducts from "./HomeComponents/HomeProducts";
import HomePartners from "./HomeComponents/HomePartners";
import HomeStatics from "./HomeComponents/HomeStatics";

const Home = () => {
  return (
    <div>
      <HomeSlider />
      <HomeService />
      <HomeAbout />
      <HomeProducts />
      <HomePartners />
      <HomeStatics />
    </div>
  );
};

export default Home;
