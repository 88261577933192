import React from "react";

const CardDashboard = ({ title, icon, number }) => {
    return (
        <>
            <div className="w-[320px] h-24 flex items-center justify-start p-2 gap-5 bg-backgroundSecondary rounded-3xl duration-300 hover:bg-backgroundTerinary">
                <span className="bg-background  bg-opacity-70 p-3 rounded-full text-5xl ml-5">
                    {icon}
                </span>
                <div className="flex-start flex-col">
                    <h2 className="text-lg">{title}</h2>
                    <h1 className="text-2xl">{number}</h1>
                </div>
            </div>
        </>
    );
};

export default CardDashboard;