import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { Sidebar } from "./AdminComponents";
import {
  ViewContact,
  Dashboard,
  AdminLogin,
  ViewOneContact,
  ViewBranches,
  AddBranches,
  EditBranches,
  ViewNews,
  AddNews,
  EditNews,
  ViewPartners,
  AddPartners,
  EditPartners,
  ViewCategories,
  AddCategories,
  EditCategories,
  ViewProducts,
  AddProducts,
  EditProducts,
  ViewAdmins,
  AddAdmins,
  ViewSliders,
  AddSliders,
  EditSliders,
} from "./AdminPages";
import { AdminContextProvider } from "./AdminAuth/AdminAuth";
import Cookies from "js-cookie";

const Admin = () => {
  const location = useLocation();
  const isLogin = location.pathname.includes("login");
  const token = Cookies.get("token");

  return (
    <>
      <AdminContextProvider>
        <div className="flex-start w-full">
          {!isLogin && token && <Sidebar />}
          <Routes>
            {token && <Route path="dashboard" element={<Dashboard />}></Route>}
            {token && <Route path="contact" element={<ViewContact />}></Route>}
            {token && (
              <Route path="contact/:id" element={<ViewOneContact />}></Route>
            )}
            {token && (
              <Route path="branches" element={<ViewBranches />}></Route>
            )}
            {token && (
              <Route path="branches/new" element={<AddBranches />}></Route>
            )}
            {token && (
              <Route path="branches/:id" element={<EditBranches />}></Route>
            )}
            {token && <Route path="news" element={<ViewNews />}></Route>}
            {token && <Route path="news/new" element={<AddNews />}></Route>}
            {token && <Route path="news/:id" element={<EditNews />}></Route>}
            {token && (
              <Route path="partners" element={<ViewPartners />}></Route>
            )}
            {token && (
              <Route path="partners/new" element={<AddPartners />}></Route>
            )}
            {token && (
              <Route path="partners/:id" element={<EditPartners />}></Route>
            )}
            {token && (
              <Route path="categories" element={<ViewCategories />}></Route>
            )}
            {token && (
              <Route path="categories/new" element={<AddCategories />}></Route>
            )}
            {token && (
              <Route path="categories/:id" element={<EditCategories />}></Route>
            )}
            {token && (
              <Route path="products" element={<ViewProducts />}></Route>
            )}
            {token && (
              <Route path="products/new" element={<AddProducts />}></Route>
            )}
            {token && (
              <Route path="products/:id" element={<EditProducts />}></Route>
            )}
            {token && <Route path="admins" element={<ViewAdmins />}></Route>}
            {token && <Route path="admins/new" element={<AddAdmins />}></Route>}
            {token && <Route path="sliders" element={<ViewSliders />}></Route>}
            {token && (
              <Route path="sliders/new" element={<AddSliders />}></Route>
            )}
            {token && (
              <Route path="sliders/:id" element={<EditSliders />}></Route>
            )}
            <Route path="login" element={<AdminLogin />}></Route>
          </Routes>
        </div>
      </AdminContextProvider>
    </>
  );
  // }
};

export default Admin;
