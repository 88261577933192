import React, { useContext, useState } from "react";

import { AiOutlineHome } from "react-icons/ai";
import { HiMenuAlt3 } from "react-icons/hi";
import { IoIosLogOut, IoMdContacts } from "react-icons/io";
import { Link, NavLink, useNavigate } from "react-router-dom";
import images from "../../../assets/images";
import { useTranslation } from "react-i18next";
import { FaCodeBranch, FaHandshake, FaImage, FaRegNewspaper, FaShoppingCart } from "react-icons/fa";
import { MdAdminPanelSettings, MdCategory } from "react-icons/md";
import Cookies from "js-cookie";
import { AdminContext } from "../../AdminAuth/AdminAuth";

const Sidebar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  const { dispatch } = useContext(AdminContext);
  const handleLogOut = async () => {
    try {
      Cookies.remove("token");
      dispatch({ type: "LOGOUT" });
      navigate("/");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };
  const activeLink =
    "flex justify-start items-center gap-3 text-xl text-primary mx-5 link-hover relative after:absolute after:w-[3px] after:h-full after:-right-2 after:top-0 after:bg-primary";
  const normalLink =
    "flex justify-start items-center gap-3 text-xl mx-5 link-hover";
  return (
    <>
      <div className="w-full xl:hidden flex justify-between px-3 items-start pt-5 absolute left-0 top-0">
        <Link to="/" className="w-[90px] h-auto block">
          <img src={images.logo} alt="w-full h-full" />
        </Link>
        <button type="button" onClick={handleClick} className="text-2xl">
          <HiMenuAlt3 />
        </button>
      </div>
      <button
        type="button"
        className={
          isOpen
            ? "top-0 fixed block xl:hidden duration-300 left-0 w-full h-full bg-black bg-opacity-50  bg-clip-padding blur__effect z-30"
            : ""
        }
        onClick={handleClick}
      ></button>

      <div
        className={
          isOpen
            ? "flex-start flex-col h-[100vh] overflow-y-auto bg-backgroundSecondary flex-[0.2] gap-4 border-l-secondary border-r-[1px] xl:sticky fixed top-0 w-[280px]  z-50 right-0  duration-500"
            : "flex-start flex-col bg-backgroundSecondary flex-[0.2] gap-4 border-l-secondary border-r-[1px] fixed xl:sticky top-0 z-50 w-[250px] h-[100vh] overflow-y-auto -right-[100%] duration-500"
        }
      >
        <div className=" border-b-[1px] w-full flex-center border-b-primary py-3">
          <Link to="/" className="w-[90px] lg:w-[140px] h-auto block">
            <img src={images.logo} alt="w-full h-full" />
          </Link>
        </div>
        <ul className="flex-start flex-col gap-4 w-full ">
          <li className="w-full">
            <NavLink
              onClick={handleClick}
              title="main page"
              to="/admin/dashboard"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <AiOutlineHome className="text-2xl" />
              <span>{t("dashboard")}</span>
            </NavLink>
          </li>
          <li className="w-full">
            <NavLink
              to="/admin/categories"
              title="categories"
              onClick={handleClick}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <MdCategory className="text-2xl" />
              <span>{t("categories")}</span>
            </NavLink>
          </li>
          <li className="w-full">
            <NavLink
              to="/admin/partners"
              title="partners"
              onClick={handleClick}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <FaHandshake className="text-2xl" />
              <span>{t("partners")}</span>
            </NavLink>
          </li>
          <li className="w-full">
            <NavLink
              to="/admin/products"
              title="products"
              onClick={handleClick}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <FaShoppingCart className="text-2xl" />
              <span>{t("products")}</span>
            </NavLink>
          </li>
          <li className="w-full">
            <NavLink
              to="/admin/news"
              title="news"
              onClick={handleClick}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <FaRegNewspaper className="text-2xl" />
              <span>{t("news")}</span>
            </NavLink>
          </li>
          <li className="w-full">
            <NavLink
              to="/admin/branches"
              title="branches"
              onClick={handleClick}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <FaCodeBranch className="text-2xl" />
              <span>{t("branches")}</span>
            </NavLink>
          </li>
          <li className="w-full">
            <NavLink
              to="/admin/sliders"
              title="sliders"
              onClick={handleClick}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <FaImage className="text-2xl" />
              <span>{t("sliders")}</span>
            </NavLink>
          </li>
          <li className="w-full">
            <NavLink
              to="/admin/admins"
              title="admins"
              onClick={handleClick}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <MdAdminPanelSettings className="text-2xl" />
              <span>{t("admins")}</span>
            </NavLink>
          </li>
          <li className="w-full">
            <NavLink
              to="/admin/contact"
              title="contact"
              onClick={handleClick}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <IoMdContacts className="text-2xl" />
              <span>{t("contacts")}</span>
            </NavLink>
          </li>
          <li className="w-full">
            <button
              onClick={handleLogOut}
              className="flex justify-start items-center w-full gap-3 text-xl mx-5 link-hover pb-2"
            >
              <IoIosLogOut className="text-2xl" />
              <span>{t("logout")}</span>
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
