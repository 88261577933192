import React, { useEffect, useState } from "react";
import images from "../../assets/images";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useFetch from "../../hooks/useFetch";
import { Loading } from "../../components";

const Categories = () => {
  const { t } = useTranslation();
  const { data, loading } = useFetch(`https://api.nujoomalkawakb.com/api/categories/all?lang=${localStorage.getItem("currectLanguage") === "kr"
    ? "ku"
    : localStorage.getItem("currectLanguage")
    }`);
  const [categoryData, setCategoryData] = useState([]);
  useEffect(() => {
    if (!loading && data.length > 0) {
      setCategoryData(data);
    }
  }, [loading, data]);
  if (loading && !data) {
    return <Loading />
  } else {
    return (
      <>
        <section className="section my-10">
          <div className="container flex-center flex-col gap-5">
            <h1 className="sm:text-4xl text-2xl border-b border-b-grey w-full pb-3 text-center">
              {t("ourCategories")}
            </h1>
            <div className="w-full flex-center flex-wrap gap-4 mt-5">
              {categoryData.map((cat, i) => (
                <Link
                  key={i}
                  to={`/products/category/${cat.id}`}
                  title={cat.title}
                  className="lg:w-[23.6%] sm:w-[31.3%] w-[47%] flex-center flex-col rounded-md overflow-hidden"
                >
                  <img
                    src={cat.image_url}
                    alt={cat.title}
                    className="w-full md:h-[250px] sm:h-[210px] h-[170px]"
                  />
                  <h3 className="w-full py-3 text-ellipsis overflow-hidden whitespace-nowrap bg-primary text-white sm:text-2xl text-lg px-1 text-center">
                    {cat.title}
                  </h3>
                </Link>
              ))}
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default Categories;
