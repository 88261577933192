import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { Loading } from "../../components";
import { useTranslation } from "react-i18next";

const Search = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const query = searchParams.get("query");

  const { data, loading, error } = useFetch(
    `https://api.nujoomalkawakb.com/api/products/search?lang=en&search=${query}`
  );

  const [getData, setGetData] = useState([]);

  useEffect(() => {
    if (!loading && data.length > 0) {
      setGetData(data);
    }
  }, [data, loading]);
  if (loading || !data) {
    return <Loading />;
  } else {
    return (
      <>
        <section className="section my-10">
          <div className="container flex-center flex-col gap-5">
            <h1 className="sm:text-4xl text-2xl border-b border-b-grey w-full pb-3 text-center">
              {t("searchresults")}
            </h1>
            <div className="w-full flex-center flex-wrap gap-4 mt-5">
              {getData.map((product, i) => (
                <Link
                  key={i}
                  to={`/products/${product.id}`}
                  title={product.title}
                  className="lg:w-[23.6%] sm:w-[31.3%] w-[47%] flex-center flex-col rounded-md overflow-hidden"
                >
                  <img
                    src={product.image_url}
                    alt={product.title}
                    className="w-full sm:h-[250px] h-[210px]"
                  />
                  <div className="w-full py-3 bg-primary text-white sm:text-lg text-base px-1 text-center flex-center flex-col">
                    <div className="flex-start gap-3 w-full">
                      <h3>{t("name")}</h3>
                      <span>{product.title}</span>
                    </div>
                    <div className="flex-start gap-3 w-full">
                      <h3>{t("color")}</h3>
                      <span>{product.color}</span>
                    </div>
                    <div className="flex-start gap-3 w-full">
                      <h3>{t("code")}</h3>
                      <span>{product.code}</span>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default Search;
