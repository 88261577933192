import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import images from "../../../assets/images";
import { useTranslation } from "react-i18next";
import useFetch from "../../../hooks/useFetch";
import { Loading } from "../../../components";
const HomeProducts = () => {
  const { t } = useTranslation();
  const { data, loading } = useFetch(`https://api.nujoomalkawakb.com/api/products/latest?lang=${localStorage.getItem("currectLanguage") === "kr"
    ? "ku"
    : localStorage.getItem("currectLanguage")
    }`);
  const [productData, setProductData] = useState([]);
  useEffect(() => {
    if (!loading && data.length > 0) {
      setProductData(data);
    }
  }, [loading, data]);
  if (loading && !data) {
    return <Loading />
  } else {
    return (
      <>
        <div className="w-full flex-center flex-col gap-5 relative mt-5">
          <h2 className="text-xl py-2 px-10 rounded-xl border-[1px] border-backgroundSecondary bg-backgroundTertiary">
            {t("products")}
          </h2>
          <div className="w-full relative h-auto">
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              navigation={true}
              breakpoints={{
                350: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                550: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                640: {
                  slidesPerView: 2.5,
                  spaceBetween: 5,
                },
                769: {
                  slidesPerView: 3,
                  spaceBetween: 3,
                },
                960: {
                  slidesPerView: 3.5,
                  spaceBetween: 5,
                },
                1140: {
                  slidesPerView: 4.5,
                  spaceBetween: 5,
                },
                1350: {
                  slidesPerView: 6,
                  spaceBetween: 25,
                },
              }}
              modules={[Navigation]}
              className="w-full h-full"
            >
              {productData.map((product, i) => (
                <SwiperSlide key={i}>
                  <Link
                    to={`/products/${product.id}`}
                    title={product.title}
                    className="w-full flex-center gap-3 flex-col"
                  >
                    <img
                      src={product.image_url
                      }
                      className="w-[200px] h-[200px] object-cover rounded-md"
                      alt={product.title}
                    />
                    <h3 className="w-full overflow-hidden text-ellipsis whitespace-nowrap link-hover text-center text-xl">
                      {product.title}
                    </h3>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </>
    );
  }
};

export default HomeProducts;
