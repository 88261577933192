import React, { useContext, useState } from "react";
import images from "../../../assets/images";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { ButtonLoading } from "../../../components";
import { AdminContext } from "../../AdminAuth/AdminAuth";
import { useTranslation } from "react-i18next";
// import { ButtonLoading } from "../../../components";

const AdminLogin = () => {
  const { t } = useTranslation();
  const { dispatch } = useContext(AdminContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [loginLoading, setLoginLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginLoading(true);

    try {
      const formDataObject = new FormData();
      formDataObject.append("username", formData.username);
      formDataObject.append("password", formData.password);

      const response = await fetch(
        "https://api.nujoomalkawakb.com/api/admins/login",
        {
          method: "POST",
          body: formDataObject,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const adminData = await response.json(); // Assuming the response contains admin data
      const token = adminData.token;
      Cookies.set("token", token, { expires: 365 });
      setLoginLoading(false);
      dispatch({ type: "LOGIN_SUCCESS", payload: adminData });
      navigate("/admin/dashboard");
    } catch (error) {
      // Dispatch a "LOGIN_FAILURE" action with the error message
      dispatch({ type: "LOGIN_FAILURE", payload: "Login failed" });
      setLoginLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <section className="w-full">
        <div className="w-full flex-center gap-10 h-[100vh]">
          <img
            src={images.kitchen}
            alt="auth"
            className="absolute top-0 left-0 w-full h-full object-cover "
          />
          <div className="flex sm:w-auto text-white w-[95%] md:items-start sm:justify-start justify-center items-center flex-col gap-4 relative backdrop-blur-md bg-black bg-opacity-30 p-5 rounded-lg">
            <h1 className="text-3xl">{t("login")}</h1>
            <span className="text-slate-400 text-sm">{t("loginText")}</span>
            <form className="flex-start flex-col w-full gap-5">
              <div className="flex-start w-full relative flex-col gap-y-1">
                <label htmlFor="username">{t("username")}:</label>
                <input
                  type="text"
                  name="username"
                  id="username"
                  value={formData.username}
                  // placeholder="ئیمەیڵ"
                  onChange={handleChange}
                  className="border-b outline-none sm:w-[350px] w-full h-[40px] bg-primary bg-opacity-0 text-white"
                />
              </div>
              <div className="flex-start w-full relative flex-col gap-y-1">
                <label htmlFor="password">{t("password")}</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  value={formData.password}
                  // placeholder="تێپەرە وشە"
                  onChange={handleChange}
                  className="border-b outline-none sm:w-[350px] w-full h-[40px] bg-primary bg-opacity-0 text-white"
                />
              </div>
              <button
                type="submit"
                onClick={handleLogin}
                className="sm:w-[350px] w-full relative h-[45px] duration-300 transition-colors hover:bg-backgroundTerinary bg-backgroundSecondary text-black text-base rounded-md px-2 py-2"
              >
                {!loginLoading && <span>{t("login")}</span>}
                {loginLoading && <ButtonLoading />}
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdminLogin;
