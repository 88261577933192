import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaWhatsapp } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { Loading } from "../../components";
import useFetch from "../../hooks/useFetch";

const Branches = () => {
  const { t } = useTranslation();
  const { data, loading } = useFetch(`https://api.nujoomalkawakb.com/api/branches/all?lang=${localStorage.getItem("currectLanguage") === "kr"
    ? "ku"
    : localStorage.getItem("currectLanguage")
    }`);
  const [branchesData, setBranchesData] = useState([]);
  useEffect(() => {
    if (!loading && data.length > 0) {
      setBranchesData(data);
    }
  }, [loading, data]);
  if (loading && !data) {
    return <Loading />
  } else {
    return (
      <>
        <section className="section my-10">
          <div className="container gap-5 flex-center flex-col">
            <h1 className="sm:text-4xl text-2xl border-b border-b-grey w-full pb-3 text-center">
              {t("ourBranches")}
            </h1>
            <div className="w-full flex-center gap-5 flex-wrap">
              {branchesData.map((branch, i) => (
                <div key={i} className="lg:w-[31.33%] sm:w-[44.5%] w-full flex-start flex-col gap-3 bg-white rounded-md overflow-hidden">
                  <div className="w-full h-[300px] overflow-hidden">
                    <iframe
                      title="iframe"
                      src={branch.src}
                      llowFullScreen=""
                      loading="lazy"
                      className="w-full h-full"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                  <div className="flex-start flex-col gap-2 p-2 w-full">
                    <h1 className="text-2xl">{branch.title}</h1>
                    <div className="w-full flex gap-1 justify-start items-start flex-col">
                      <div className="w-fill flex-start gap-2">
                        <FaLocationDot className="text-xl" />
                        <span className="text-base">{branch.location}</span>
                      </div>
                      <div className="w-fill flex-start gap-2">
                        <FaWhatsapp className="text-xl" />
                        <a
                          href={`https://api.whatsapp.com/send?phone=${branch.phone}`}
                          title="number"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-base link-hover"
                        >
                          {branch.phone}
                        </a>
                      </div>
                      <div className="w-fill flex-start gap-2">
                        <MdEmail className="text-xl flex-shrink-0" />
                        <a
                          href="mailto:nujoomalkawakb@gmail.com"
                          className="link-hover"
                        >
                          {branch.email}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default Branches;
