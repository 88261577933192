import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import images from "../../../assets/images";
import { useTranslation } from "react-i18next";
import useFetch from "../../../hooks/useFetch";
import { Loading } from "../../../components";
const HomePartners = () => {
  const { t } = useTranslation();
  const { data, loading } = useFetch(`https://api.nujoomalkawakb.com/api/partners/latest?lang=${localStorage.getItem("currectLanguage") === "kr"
    ? "ku"
    : localStorage.getItem("currectLanguage")
    }`);
  const [partnerData, setPartnerData] = useState([]);
  useEffect(() => {
    if (!loading && data.length > 0) {
      setPartnerData(data);
    }
  }, [loading, data]);
  if (loading && !data) {
    return <Loading />
  } else {
    return (
      <>
        <div className="w-full flex-center flex-col gap-5 relative mt-5">
          <h2 className="text-xl py-2 px-10 rounded-xl border-[1px] border-backgroundSecondary bg-backgroundTertiary">
            {t("partners")}
          </h2>
          <div className="w-full relative h-auto">
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              navigation={true}
              breakpoints={{
                300: {
                  slidesPerView: 1.5,
                  spaceBetween: 5,
                },
                480: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                530: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                640: {
                  slidesPerView: 2.5,
                  spaceBetween: 5,
                },
                700: {
                  slidesPerView: 3,
                  spaceBetween: 5,
                },

                960: {
                  slidesPerView: 4.5,
                  spaceBetween: 25,
                },
                1340: {
                  slidesPerView: 5.5,
                  spaceBetween: 5,
                },
                1660: {
                  slidesPerView: 7,
                  spaceBetween: 25,
                },
              }}
              modules={[Navigation]}
              className="w-full h-full"
            >
              {partnerData.map((partner, i) => (
                <SwiperSlide key={i}>
                  <Link
                    to={`/partners/${partner.id}`}
                    title={partner.title}
                    className="w-full flex-center gap-3 flex-col relative"
                  >
                    <img
                      src={partner.image_url}
                      className="w-[270px] h-[270px] object-cover rounded-md"
                      alt={partner.title}
                    />
                    <h3 className="w-full z-20 overflow-hidden text-white text-ellipsis whitespace-nowrap link-hover text-center text-xl absolute top-[80%] -translate-x-[50%] left-[50%] -translate-y-[50%]">
                      {partner.title}
                    </h3>
                    <div className="bg-gradient-to-t absolute top-0 left-0 w-full h-full from-[#00000059] to-[#ffffff0e] z-10"></div>
                  </Link>
                </SwiperSlide>
              ))}

            </Swiper>
          </div>
        </div>
      </>
    );
  }
};

export default HomePartners;
