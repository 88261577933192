import React from "react";
import { MdRotateRight } from "react-icons/md";

const ButtonLoading = () => {
    return (
        <span >
            <MdRotateRight className="animate-spin text-2xl" />
        </span>
    );
};

export default ButtonLoading;