import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { useTranslation } from "react-i18next";
import { Loading } from "../../components";

const News = () => {
  const { t } = useTranslation();
  const { data, loading } = useFetch(
    `https://api.nujoomalkawakb.com/api/news/all?lang=${localStorage.getItem("currectLanguage") === "kr"
      ? "ku"
      : localStorage.getItem("currectLanguage")
    }`
  );
  const [newsData, setNewsData] = useState([]);
  useEffect(() => {
    if (!loading && data.length > 0) {
      setNewsData(data);
    }
  }, [loading, data]);
  if (loading && !data) {
    return <Loading />
  } else {
    return (
      <>
        <section className="w-full items-start flex justify-center min-h-[100vh] my-6">
          <div className="container flex justify-center items-center flex-col gap-5">
            <h1 className="sm:text-4xl text-2xl">{t("news")}</h1>
            <div className="w-full flex-start gap-5 flex-wrap">
              {newsData.map((news, i) => (
                <div
                  key={i}
                  className="lg:w-[32%] md:w-[31%] sm:w-[48%] w-full bg-backgroundSecondary flex-start flex-col gap-2"
                >
                  <img
                    src={news.image_url}
                    className="rounded-md w-full  h-[300px] min-h-[200px]"
                    alt={news.title}
                  />
                  <Link
                    to={`/news/${news.id}`}
                    title={news.title}
                    className="sm:text-2xl text-lg px-2"
                  >
                    {news.title}
                  </Link>
                  <p className="text-grey text-sm px-2">
                    {news.description.slice(0, 100)}...
                  </p>
                  <Link
                    to={`/news/${news.id}`}
                    title={news.title}
                    className="primary-button mx-2 mb-2"
                  >
                    {t("seeMore")}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default News;
