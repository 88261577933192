import { FaFacebookF, FaInstagram, FaPhone, FaWhatsapp } from "react-icons/fa";
import images from "../../assets/images";
import React from "react";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="w-full flex-center flex-col">
        <div className="w-full bg-dark flex justify-between items-start gap-5 flex-wrap ltr:pr-5 rtl:pl-5">
          <div className="bg-white">
            <img
              src={images.logo}
              className="lg:w-[350px] w-[200px] h-auto"
              alt="logo"
            />
          </div>
          <div className="flex-start gap-2 flex-col mt-5 mx-2">
            <div className="flex-center gap-1 text-2xl text-white">
              <FaPhone />
              <a
                className="text-sm text-white change-font"
                href="https://api.whatsapp.com/send?phone=9647504482440"
                title="number"
                target="_blank"
                rel="noopener noreferrer"
              >
                +964 750 448 2440
              </a>
            </div>
            <span className="h-full w-[1px] bg-grey"></span>
            <div className="flex-center gap-1 text-2xl text-white">
              <FaPhone />
              <a
                className="text-sm text-white change-font"
                href="https://api.whatsapp.com/send?phone=9647501128070"
                title="number"
                target="_blank"
                rel="noopener noreferrer"
              >
                +964 750 112 8070
              </a>
            </div>
            <span className="h-full w-[1px] bg-grey"></span>
            <div className="flex-center gap-1 text-2xl text-white">
              <MdEmail />
              <a
                href="mailto:nujoomalkawakb@gmail.com"
                className="text-sm text-white change-font"
              >
                nujoomalkawakb@gmail.com
              </a>
            </div>
            <span className="h-full w-[1px] bg-grey"></span>
            <div className="flex-center gap-1 text-2xl text-white">
              <FaLocationDot />
              <span className="text-sm text-white">{t("companyLocation")}</span>
            </div>
          </div>
          <div className="flex-start gap-2 text-white flex-col mt-5 mx-2">
            <Link
              to="/"
              title="home"
              className="lg:text-xl text-base link-hover"
            >
              {t("home")}
            </Link>
            <Link
              to="/partners"
              title="partners"
              className="lg:text-xl text-base link-hover"
            >
              {t("partners")}
            </Link>
            <Link
              to="/products"
              title="products"
              className="lg:text-xl text-base link-hover"
            >
              {t("products")}
            </Link>
            <Link
              to="/branches"
              title="branches"
              className="lg:text-xl text-base link-hover"
            >
              {t("branches")}
            </Link>
            <Link
              to="/news"
              title="news"
              className="lg:text-xl text-base link-hover"
            >
              {t("news")}
            </Link>
            <Link
              to="/about"
              title="about"
              className="lg:text-xl text-base link-hover"
            >
              {t("about")}
            </Link>
            <Link
              to="/contact"
              title="contact"
              className="lg:text-xl text-base link-hover"
            >
              {t("contact")}
            </Link>
          </div>
          <div className="flex-center text-white gap-2 flex-col mt-5">
            <h3 className="text-2xl">{t("contactUs")}</h3>
            <div className="grid grid-cols-2 grid-rows-2 gap-3">
              <a
                href="https://api.whatsapp.com/send?phone=9647504482440"
                title="number"
                className="text-2xl"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaWhatsapp />
              </a>
              <a href="mailto:nujoomalkawakb@gmail.com" className="text-2xl">
                <MdEmail />
              </a>
              <a
                href="https://www.facebook.com/nujoom.alkawakb"
                title="number"
                className="text-2xl"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF />
              </a>
              <a
                href="https://www.instagram.com/nujoom_alkawakb/"
                title="number"
                className="text-2xl"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
            </div>
          </div>
        </div>
        <div className="w-full bg-black text-center p-3 text-sm text-white">
          Designed By : <a
            href="https://www.facebook.com/shahram.fatah"
            title="number"
            target="_blank"
            rel="noopener noreferrer"
          >
            Shahram Fatah
          </a> & Developed By : <a
            href="https://www.facebook.com/mohamedomar112"
            title="number"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mohamed Omer
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
