import { Routes, Route, useLocation } from "react-router-dom";
import {
  About,
  Branches,
  Categories,
  CategoriesDetail,
  Contact,
  Home,
  News,
  NewsDetails,
  Partners,
  PartnersDetails,
  ProductDetails,
  Search,
} from "./pages";
import { Navbar, Footer } from "./components";
import Admin from "./admin/Admin";

function App() {
  const location = useLocation();
  const isAdminRoute =
    location.pathname.includes("admin") || location.pathname.includes("login");
  return (
    <>
      {!isAdminRoute && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/news" element={<News />}></Route>
        <Route path="/news/:id" element={<NewsDetails />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/partners" element={<Partners />}></Route>
        <Route path="/partners/:id" element={<PartnersDetails />}></Route>
        <Route path="/products" element={<Categories />}></Route>
        <Route path="/products/:id" element={<ProductDetails />}></Route>
        <Route
          path="/products/category/:id"
          element={<CategoriesDetail />}
        ></Route>
        <Route path="/branches" element={<Branches />}></Route>
        <Route path="/search" element={<Search />}></Route>
        <Route path="/admin/*" element={<Admin />} />
      </Routes>
      {!isAdminRoute && <Footer />}
    </>
  );
}

export default App;
