import React, { useEffect, useState } from "react";
import images from "../../assets/images";
import { Link, useParams } from "react-router-dom";
import { Loading } from "../../components";
import useFetch from "../../hooks/useFetch";
import { useTranslation } from "react-i18next";

const CategoriesDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data, loading } = useFetch(
    `https://api.nujoomalkawakb.com/api/products/all?lang=${localStorage.getItem("currectLanguage") === "kr"
      ? "ku"
      : localStorage.getItem("currectLanguage")
    }&categoryId=${id}`
  );
  const [categoryData, setCategoryData] = useState([]);
  useEffect(() => {
    if (!loading && data.length > 0) {
      setCategoryData(data);
    }
  }, [loading, data]);
  if (loading && !data) {
    return <Loading />;
  } else {
    return (
      <>
        <section className="section my-10">
          <div className="container flex-center flex-col gap-5">
            <h1 className="sm:text-4xl text-2xl border-b border-b-grey w-full pb-3 text-center">
              {t("ourCategories")}
            </h1>
            {categoryData.map((cat, i) => (
              <div key={i} className="w-full flex-start flex-col gap-5">
                <div className="flex justify-start items-center w-full">
                  <div className="bg-white h-28 w-28 flex-shrink-0 flex-center rounded-full overflow-hidden z-10">
                    <img
                      src={cat.partner_image_img}
                      alt={cat.partner}
                      className="w-[90%]"
                    />
                  </div>
                  <div className="sm:w-[300px] w-full sm:min-w-fit min-w-[80%] h-16 bg-grey relative -left-8 rounded-3xl text-center flex-center text-white text-2xl">
                    {cat.partner}
                  </div>
                </div>
                <div className="w-full flex-center flex-wrap gap-4 mt-5">
                  {cat.products.map((product, i) => (
                    <Link
                      key={i}
                      to={`/products/${product.id}`}
                      title={product.title}
                      className="lg:w-[23.6%] sm:w-[31.3%] w-[47%] flex-center flex-col rounded-md overflow-hidden"
                    >
                      <img
                        src={product.image_url}
                        alt={product.title}
                        className="w-full md:h-[250px] sm:h-[210px] h-[170px]"
                      />
                      <div className="w-full py-3 bg-primary text-white sm:text-lg text-base px-1 text-center flex-center flex-col">
                        <div className="flex-start gap-3 w-full">
                          <h3 className="sm:text-base text-sm">{t("name")}</h3>
                          <span className="sm:text-base text-sm">{product.title}</span>
                        </div>
                        <div className="flex-start gap-3 w-full">
                          <h3 className="sm:text-base text-sm">{t("color")}</h3>
                          <span className="sm:text-base text-sm">{product.color}</span>
                        </div>
                        <div className="flex-start gap-3 w-full">
                          <h3 className="sm:text-base text-sm">{t("code")}</h3>
                          <span className="sm:text-base text-sm">{product.code}</span>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </section>
      </>
    );
  }
};

export default CategoriesDetail;
