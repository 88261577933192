import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import { Navigation, Autoplay } from "swiper/modules";
import images from "../../../assets/images";
import { FaFacebookF, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { useTranslation } from "react-i18next";
import useFetch from "../../../hooks/useFetch";

const HomeSlider = () => {
  const { data, loading, error, refetch } = useFetch(
    `https://api.nujoomalkawakb.com/api/sliders/all`
  );
  const [getData, setGetData] = useState([]);
  useEffect(() => {
    if (!loading && data) {
      setGetData(data);
    }
  }, [loading, data]);
  const { t } = useTranslation();
  return (
    <div className="relative w-full lg:h-[80vh] h-auto">
      <Swiper
        navigation={true}
        modules={[Navigation, Autoplay]}
        className="w-full h-full z-10"
        style={{ direction: "ltr" }}
        loop={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: true,
        }}
      >
        {getData.map((slider, i) => (
          <SwiperSlide className="w-full h-full" key={i}>
            <div className="relative w-full h-full">
              <img
                className="md:min-h-[700px] sm:min-h-[500px] min-h-[420px] object-cover"
                src={slider.image_url}
                alt={slider.title}
              />
              <div className="absolute text-white top-[50%] h-full -translate-y-[50%] flex justify-center items-center ltr:left-0 rtl:right-0 ltr:bg-gradient-to-r rtl:bg-gradient-to-l from-[#000000c5] to-[#00000017] lg:w-[50%] w-full">
                <div className="flex ltr:justify-start ltr:items-start rtl:justify-end rtl:items-end flex-col gap-1 ltr:border-l-8 rtl:border-r-8 border-primary px-10 py-10 relative after:top-0 ltr:after:left-0 rtl:after:right-0 after:content-[''] after:bg-primary after:w-[30%] after:h-[8px] after:absolute before:bottom-0 ltr:before:left-0 rtl:before:right-0 before:content-[''] before:bg-primary before:w-[30%] before:h-[8px] before:absolute">
                  <h3 className="md:text-xl text-base">{t("welcome")}</h3>
                  <h1 className="md:text-9xl sm:text-4xl text-2xl">
                    {t("nujoom")}
                  </h1>
                  <h2 className="md:text-5xl sm:text-xl text-lg">
                    {t("kawkab")}
                  </h2>
                  <div className="flex-start gap-3 mt-5">
                    <a
                      href="https://api.whatsapp.com/send?phone=9647504482440"
                      title="number"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-2xl text-white"
                    >
                      <FaWhatsapp />
                    </a>
                    <a
                      className="text-2xl text-white"
                      href="mailto:nujoomalkawakb@gmail.com"
                    >
                      <MdEmail />
                    </a>
                    <a
                      className="text-2xl text-white"
                      href="https://www.facebook.com/nujoom.alkawakb"
                      title="number"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebookF />
                    </a>
                    <a
                      className="text-2xl text-white"
                      href="https://www.instagram.com/nujoom_alkawakb/"
                      title="number"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default HomeSlider;
