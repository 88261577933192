import React from 'react'
import { useParams } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import { Loading } from '../../components';

const NewsDetails = () => {
    const { id } = useParams();
    const { data, loading } = useFetch(
        `https://api.nujoomalkawakb.com/api/news/${id}?lang=${localStorage.getItem("currectLanguage") === "kr"
            ? "ku"
            : localStorage.getItem("currectLanguage")
        }`
    );
    if (loading && !data) {
        return <Loading />
    } else {
        return (
            <>
                <section className='section my-10'>
                    <div className="container flex-center flex-col gap-5">
                        <img src={data.image_url} alt={data.title} className='w-full h-auto rounded-lg' />
                        <h1 className='sm:text-4xl text-2xl'>{data.title}</h1>
                        <span className='text-grey'>{data.description}</span>
                    </div>
                </section>
            </>
        )
    }
}

export default NewsDetails