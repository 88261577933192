import React, { useState } from "react";
import { AiOutlineCheckCircle, AiOutlineCloudUpload } from "react-icons/ai";
import { MdReportGmailerrorred } from "react-icons/md";
import SendData from "../../../hooks/useSendData";
import { useTranslation } from "react-i18next";
import { ButtonLoading, CreatedWarning } from "../../../components";
import images from "../../../assets/images";
const AddCategories = () => {
  const { t } = useTranslation();
  const { createData, createError, createLoading, post } = SendData();
  const [img, setImg] = useState("");
  const [isSent, setIsSent] = useState(false);

  const [formData, setFormData] = useState({
    title_en: "",
    title_ar: "",
    title_ku: "",
    image: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();

    formDataToSend.append("title_en", formData.title_en);
    formDataToSend.append("title_ar", formData.title_ar);
    formDataToSend.append("title_ku", formData.title_ku);
    formDataToSend.append("image", formData.image);
    try {
      await post("https://api.nujoomalkawakb.com/api/categories/create", formDataToSend);
      setIsSent(true);
    } catch (err) {
      setIsSent(false);
    }
  };

  return (
    <>
      <div className="flex-center flex-col w-full xl:flex-[0.8] mb-10 flex-1 mt-28 gap-8">
        <div className="flex-center gap-5 md:flex-row flex-col">
          <div className="flex-start gap-2 flex-col">
            <label htmlFor="title_en">{t("title_en")}</label>
            <input
              type="text"
              id="title_en"
              value={formData.title_en}
              onChange={handleChange}
              className="outline-blue border-grey border rounded h-10 sm:w-[230px] w-[330px] bg-white bg-opacity-0 px-2"
              name="title_en"
            />
          </div>
          <div className="flex-start gap-2 flex-col">
            <label htmlFor="title_ar">{t("title_ar")}</label>
            <input
              type="text"
              value={formData.title_ar}
              onChange={handleChange}
              id="title_ar"
              name="title_ar"
              className="outline-blue border-grey border rounded h-10 sm:w-[230px] w-[330px] bg-white bg-opacity-0 px-2"
            />
          </div>
          <div className="flex-start gap-2 flex-col">
            <label htmlFor="title_ku">{t("title_ku")}</label>
            <input
              type="text"
              value={formData.title_ku}
              onChange={handleChange}
              id="title_ku"
              name="title_ku"
              className="outline-blue border-grey border rounded h-10 sm:w-[230px] w-[330px] bg-white bg-opacity-0 px-2"
            />
          </div>
        </div>
        <div className="flex-center gap-5 md:flex-row flex-col">
          <div className="flex-start flex-col gap-1">
            <label htmlFor="img" className="text-lg">
              {t("image")}
            </label>
            <input
              type="file"
              id="img"
              disabled={false}
              className="hidden"
              onChange={(e) => {
                setFormData({ ...formData, image: e.target.files[0] });
                setImg(e.target.files[0]);
              }}
              name="image"
            />
            <div className="outline-none border-grey border rounded h-11 sm:w-[230px] w-[330px] bg-transparent flex items-center justify-between">
              <div className="mx-3 font-normal text-right text-grey whitespace-nowrap w-full overflow-hidden text-ellipsis">
                {img.name}
              </div>
              <label
                htmlFor="img"
                className="text-2xl font-bold bg-primary text-white h-full flex-center w-[50px] cursor-pointer"
              >
                <AiOutlineCloudUpload />
              </label>
            </div>
          </div>
          <label
            htmlFor="img"
            className="sm:w-[460px] w-[330px] h-[250px] bg-secondary rounded-md overflow-hidden"
          >
            <img
              src={img ? URL.createObjectURL(img) : images.noImg}
              alt=""
              className="w-full h-full object-cover"
            />
          </label>
        </div>
        <button
          onClick={handleSubmit}
          type="submit"
          disabled={createLoading}
          className={`sm:w-[450px] w-[90%] flex-center relative h-[45px] bg-primary text-xl py-2 hover:bg-secondary text-white duration-300 rounded-md ${createLoading ? "disabled" : ""
            }`}
        >
          {!createLoading && <span>{t("send")}</span>}
          {createLoading && <ButtonLoading />}
        </button>
      </div>
      {isSent && (
        <CreatedWarning
          icon={<AiOutlineCheckCircle className="text-9xl text-primary" />}
          message={t("createdSuccessfully")}
        />
      )}
      {createError && (
        <CreatedWarning
          icon={<MdReportGmailerrorred className="text-9xl text-primary" />}
          message={t("fillFields")}
        />
      )}
    </>
  );
};

export default AddCategories;
