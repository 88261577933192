import Cookies from "js-cookie";
import { useState } from "react";
// import Cookies from "js-cookie";

const useDelete = () => {
    const [deleteData, setDeleteData] = useState(null);
    const [deleteError, setDeleteError] = useState(null);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const token = Cookies.get("token");

    const deleteRequest = async (url) => {
        try {
            setDeleteLoading(true);
            const response = await fetch(url, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();
            setDeleteData(responseData);
            setDeleteError(null);
        } catch (error) {
            setDeleteError(error);
            throw error; // Re-throw the error to propagate it to the caller
        } finally {
            setDeleteLoading(false);
        }
    };

    return { deleteData, deleteError, deleteLoading, deleteRequest };
};

export default useDelete;
