import React, { useState } from "react";
import SendData from "../../hooks/useSendData";
import { ButtonLoading, CreatedWarning } from "../../components";
import { MdReportGmailerrorred } from "react-icons/md";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  const { createData, createError, createLoading, post } = SendData();
  const [isSent, setIsSent] = useState(false);
  const [formData, setFormData] = useState({
    full_name: "",
    description: "",
    phone_number: "",
    email: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();

    formDataToSend.append("full_name", formData.full_name);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("phone_number", formData.phone_number);
    formDataToSend.append("email", formData.email);

    try {
      await post("https://api.nujoomalkawakb.com/api/contact/create", formDataToSend);
      setIsSent(true);
    } catch (err) {
      setIsSent(false);
    }
  };
  return (
    <>
      <section className="section  mt-10">
        <div className="bg-white flex-start gap-5 flex-col md:p-5 p-2 max-w-[500px] w-full rounded-xl">
          <h2 className="md:text-5xl text-2xl">{t("contact")}</h2>
          <span className="text-grey text-sm">{t("fillFields")}</span>
          <div className="flex-start w-full flex-col gap-1">
            <label htmlFor="full_name" className="text-grey">
              {t("fullName")}
            </label>
            <input
              type="text"
              className="text-sm border border-lightGrey w-full h-[40px] rounded-md  px-3 outline-blue"
              placeholder="#### #######"
              id="full_name"
              value={formData.full_name}
              onChange={handleChange}
              name="full_name"
            />
          </div>
          <div className="flex-start w-full flex-col gap-1">
            <label htmlFor="phone_number" className="text-grey">
              {t("phoneNumber")}
            </label>
            <input
              type="number"
              className="text-sm border border-lightGrey w-full h-[40px] rounded-md  px-3 outline-blue"
              placeholder="xxxx xxx xxxx"
              id="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              name="phone_number"
            />
          </div>
          <div className="flex-start w-full flex-col gap-1">
            <label htmlFor="email" className="text-grey">
              {t("email")}
            </label>
            <input
              type="email"
              className="text-sm border border-lightGrey w-full h-[40px] rounded-md  px-3 outline-blue"
              placeholder="example@gmail.com"
              id="email"
              value={formData.email}
              onChange={handleChange}
              name="email"
            />
          </div>
          <div className="flex-start w-full flex-col gap-1">
            <label for="description" className="text-grey">
              {t("description")}
            </label>
            <textarea
              type="text"
              id="description"
              className="text-sm border resize-none border-lightGrey w-full h-[80px] rounded-md p-3 outline-blue"
              placeholder={t("note")}
              value={formData.description}
              onChange={handleChange}
              name="description"
            ></textarea>
          </div>

          <button onClick={handleSubmit} className="w-full h-[40px] relative rounded-md flex-center text-center bg-primary text-white">
            {!createLoading && <span>send</span>}
            {createLoading && <ButtonLoading />}
          </button>
        </div>
        {isSent && (
          <CreatedWarning
            icon={<AiOutlineCheckCircle className="text-9xl text-primary" />}
            message={t("sendSuccessfully")}
          />
        )}
        {createError && (
          <CreatedWarning
            icon={<MdReportGmailerrorred className="text-9xl text-primary" />}
            message={t("fillFields")}

          />
        )}
      </section>
    </>
  );
};

export default Contact;
