import React from "react";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  return (
    <section className="section mt-10">
      <div className="container flex-start flex-col gap-5">
        <div className="flex w-full justify-center items-center flex-wrap gap-5">
          <div className="flex-start flex-col gap-2 lg:w-[23%] md:w-[31%] sm:w-[47%] w-full rounded-md overflow-hidden bg-white">
            <h3 className="text-xl font-semibold bg-primary text-white w-full px-2 py-4 text-center">
              {t("aboutNujoom")}
            </h3>
            <span className="text-grey text-center bg-white min-h-[200px] p-2">{t("about1")}</span>
          </div>
          <div className="flex-start flex-col gap-2 lg:w-[23%] md:w-[31%] sm:w-[47%] w-full rounded-md overflow-hidden bg-white">
            <h3 className="text-xl font-semibold bg-primary text-white w-full px-2 py-4 text-center">{t("ourStory")}</h3>
            <span className="text-grey text-center bg-white min-h-[200px] p-2">{t("ourStoryText")}</span>
          </div>
          <div className="flex-start flex-col gap-2 lg:w-[23%] md:w-[31%] sm:w-[47%] w-full rounded-md overflow-hidden bg-white">
            <h3 className="text-xl font-semibold bg-primary text-white w-full px-2 py-4 text-center">
              {t("ourMission")}
            </h3>
            <span className="text-grey text-center bg-white min-h-[200px] p-2">{t("ourMissionText")}</span>
          </div>
          <div className="flex-start flex-col gap-2 lg:w-[23%] md:w-[31%] sm:w-[47%] w-full rounded-md overflow-hidden bg-white">
            <h3 className="text-xl font-semibold bg-primary text-white w-full px-2 py-4 text-center">{t("setApart")}</h3>
            <span className="text-grey text-center bg-white min-h-[200px] p-2">{t("setApartText")}</span>
          </div>
        </div>
        <span className="text-grey text-center">{t("about2")}</span>
      </div>
    </section>
  );
};

export default About;
