import { useState } from "react";
import Cookies from "js-cookie";

const SendData = () => {
  const [createData, setData] = useState(null);
  const [createError, setCreateError] = useState(null);
  const [createLoading, setLoading] = useState(false);
  const token = Cookies.get("token");
  const post = async (url, formData, headers = {}) => {
    try {
      setLoading(true);

      const res = await fetch(url, {
        method: "POST",
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }

      const data = await res.json();

      setData(data);
      setCreateError(null);
    } catch (error) {
      setCreateError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };
  return { createData, createError, createLoading, post };
};

export default SendData;
