import { useState } from "react";
import Cookies from "js-cookie";

const useUpdateData = () => {
  const [updateData, setUpdateData] = useState(null);
  const [updateError, setUpdateError] = useState(null);
  const [updateLoading, setUpdateLoading] = useState(false);
  const token = Cookies.get("token");

  const put = async (url, formData, headers = {}) => {
    try {
      setUpdateLoading(true);

      const res = await fetch(url, {
        method: "PUT",
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }

      const data = await res.json();

      setUpdateData(data);
      setUpdateError(null);
    } catch (error) {
      setUpdateError(error);
      throw error;
    } finally {
      setUpdateLoading(false);
    }
  };

  return { updateData, updateError, updateLoading, put };
};

export default useUpdateData;
